
import { marked } from 'marked';

export default {
  name: 'MarkdownRenderer',
  props: {
    source: {
      type: String,
      default: '**Hello World!**',
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      marked: '',
    };
  },
  watch: {
    source: {
      handler(value: string) {
        /**
          Проблема 1:
          Разметка Яндекса GPT может помещать списки внутри ячеек таблиц.
          У каждого такого элемента списка в начале добавляется признак конца строки \n.
          Плагин marked считает, что при встрече такого символа рендер таблицы надо прекратить.
          Решение:
          Разделить всю строку разметки на блоки между символами | и |.
          Каждый такой блок спарсить с помощью marked отдельно.
          Все блоки склеить обратно и прогнать через marked ещё раз.
        */
        /**
          Проблема 2:
          Разметка Яндекса GPT может разбивать таблицу пустой строкой.
          Плагин marked считает, что это две разных таблицы.
          А так как у второй таблицы нет заголовка, плагин marked её не рендерит
          Решение:
          Искать таблицу без заголовка. Если найдём, удалить пустую строку перед ней.
        */

        const resultLines = [] as string[];
        let listLines = [] as string[];
        // удобнее перебирать массив в обратном порядке
        value.split(/\n/).reverse().forEach((line) => {
          if (line[0] !== '|' && line[line.length - 1] === '|') {
            // признак конца таблицы есть, а признака начала нет - имеем список в таблице
            const lines = line.split('|');
            if (!listLines.length) {
              // если мы ещё не запоминали элементы списка в таблице,
              // то запоминаем его (из начала строки), а остаток считаем корректным
              listLines = [lines[0]];
              resultLines.push(lines.slice(1, lines.length).join('|'));
            } else {
              // если мы уже запоминали элементы списка в таблице
              // это значит, что ячейка закончилась и началась новая:
              // 1. парсим то, что запомнили ранее
              // 2. первый элемент списка запоминаем для дальнейшего использования
              // 3. в последнюю корректную строку дописываем то, что спарсилось
              const listLine = this.parse(listLines.join('\n'));
              listLines = [lines[0]];
              resultLines[resultLines.length - 1] = `${line} ${listLine} |${resultLines[resultLines.length - 1]}`;
            }
          } else if (listLines.length) {
            // если мы уже запоминали элементы списка в таблице
            if (line.indexOf('|') > -1) {
              // ячейка закончилась и началась новая:
              // 1. парсим то, что запомнили ранее
              // 2. первый элемент списка запоминаем для дальнейшего использования
              // 3. в последнюю корректную строку дописываем то, что спарсилось
              const listLine = this.parse(listLines.join('\n'));
              resultLines[resultLines.length - 1] = `${line} ${listLine} |${resultLines[resultLines.length - 1]}`;
              listLines = [];
            } else {
              listLines.unshift(line);
            }
          } else if (line === '') {
            const prevPrevLine = resultLines[resultLines.length - 2];
            if (prevPrevLine && (prevPrevLine[0] === '|' && prevPrevLine.indexOf('---') === -1)) {
              // решение проблемы 2:
              // не добавляем пустую строку, если следующая за ней таблица
              // не имеет заголовка
            } else {
              resultLines.push(line);
            }
          } else {
            resultLines.push(line);
          }
        });
        this.marked = this.parse(resultLines.reverse().join('\n'));
        this.$emit('parse', this.marked);
      },
      immediate: true,
    },
  },
  methods: {
    parse(source: string) {
      // парсим строку, только если это не признак таблицы | --- |, иначе возвращаем как есть
      if (!source || source === '\n' || source === ' --- ') {
        return source;
      }
      /**
          Проблема 3:
          Плагин marked неправильно обрабатывает нумерованные списки, в которых есть нумерация элементов больше 9.
          Ко всем таким элементам списка добавляется новый уровень вложенности.
          Решение:
          Ищем все строки, вложенные в такие списки, и добавляем к ним спереди ещё один пробел
          (или больше, если вложенность > 1).
          В таком случае плагин при генерации HTML
          определяет уровень вложенности верно и не глючит.
          Решение подходит для нумерованных списков с количеством элементов не более 99.
      */
      let olStack = [] as number[];
      const linesArray = source.split(/\n/).map((line) => {
        const trimmedLine = line.trimStart();
        // является ли строка элементом списка с двузначным номером:
        const isBigOl = /^\d{2,}. /.test(trimmedLine);
        const spacesCount = line.length - trimmedLine.length;
        // определяем, на каком уровне вложенности в списках находится строка:
        const olLevel = olStack.findLastIndex((el) => el < spacesCount) + 1;
        olStack = olStack.slice(0, olLevel);
        // исправляем количество пробелов в начале строки:
        const newLine = ' '.repeat(spacesCount + olStack.length) + trimmedLine;
        if (isBigOl) {
          // запоминаем новый уровень вложенности для полседующих строк:
          olStack.push(spacesCount);
        }
        return newLine;
      });
      const parsedValue = linesArray.join('\n');
      const result = marked.parse(parsedValue).trim().split('\n').join('');
      return result;
    },
    onClickHandler(e) {
      if (e.target.nodeName === 'A') {
        const { href } = e.target;
        this.$emit('external-link-click', href);
      }
    },
  },
};
